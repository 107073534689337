<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "FiveWaysToSilenceYourInnerCriticWhileDrafting",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="FiveWaysToSilenceYourInnerCriticWhileDrafting">
      <SuawParagraph
        text="Those of us just starting out on our writing journeys can sometimes get bogged down by inner doubts. The worst critic is the one living inside your own head, available with scathing retorts on a relentless, 24/7 schedule."
      />
      <SuawParagraph
        text="For many of us who are working on a first draft, this can spell disaster. The inner critic’s time must come, but that time is at a later stage, when you’re revising."
      />
      <SuawParagraph text="But for now, you have one task at hand. It is your only essential task. Your task is to write." />
      <SuawParagraph
        text="And to do so, you need to slay your inner critic – or at the very least, mute them – so that you can get out from what Andy Weir calls &quot;<a target='_blank' title='https://medium.com/@penguinrandomus/the-tyranny-of-the-blank-page-4-writing-tips-from-andy-weir-709992be37b7' href='https://medium.com/@penguinrandomus/the-tyranny-of-the-blank-page-4-writing-tips-from-andy-weir-709992be37b7'>the tyranny of the blank page</a>&quot; and have something to show for your brilliant ideas! Here's how:"
      />
      <SuawHeading level="3" content="1. Give your inner critic a name and face them down directly to get them to hush" />
      <SuawParagraph
        text="If your inner critic has always felt formidable, name them something worthy of their power. For example, I’ve named mine The Naysayer, which makes me…wait for it…The Naysayer Slayer! On the other hand, you might consider a goofy name to make your inner critic a little less intimidating, like Oscar the Inner Grouch, or Fussy McGee. A sample conversation could go something like:"
      />
      <SuawParagraph text="<strong>Fussy McGee: </strong>That was the worst sentence I’ve ever read in my life!" />
      <SuawParagraph text="<strong>You: </strong>Pipe down, Fussy McGee, and get out of my way! I have a draft to finish and you’re not helping." />
      <SuawHeading level="3" content="2. Say your affirmations out loud" />
      <SuawParagraph text="Some of my favorite affirmations are: " />
      <SuawParagraph
        text='<ul><li>"I am more than my inner critic."</li><li>"I refuse to be my own worst enemy."</li><li>"Critique comes later. Now, it’s time to write!"</li></ul>'
      />
      <SuawHeading level="3" content="3. Schedule a time to let out your inner critic for a short while" />
      <SuawParagraph
        text="We recommend doing this no more than every week or two. Create a recurring appointment on your calendar that lets you sit down with your inner critic and hear them out. Many times, your inner critic is needlessly disruptive to your drafting process, but sometimes, that niggling voice inside of your head is onto something that might help unblock you if you take time to listen. Use the following template to guide your critic, so you don’t go off the rails into destructive, never-ending hand-wringing:"
      />
      <SuawParagraph
        text="<ul><li>What specific pages or concepts am I going to critique during this session?</li><li>What do I dislike about my project?</li><li>What do I actually like or even love about it?</li><li>Is there a way I can achieve my broader writing goal in a different way than I’ve already done in these pages/using this concept? If yes, how might I do this? If no, how might I modify what I already have to achieve my goal?</li></ul>"
      />
      <SuawParagraph
        text="Think out loud and document your critic’s comments. You can create a video of yourself thinking out loud, or make an audio recording on your phone, or jot down your notes in a document."
      />
      <SuawParagraph
        text="Finally – and this is important – end the meeting with your inner critic on time. If you’ve set aside 60 minutes to hear out your inner critic, spend only 60 minutes doing so. Then step away from critique mode to do something nice for yourself, or if you’re so inclined, even jump back into drafting."
      />
      <SuawHeading level="3" content="4. Leave the critiquing part to other readers" />
      <SuawParagraph
        text="Many times, our inner critic may pick up on something that can be useful to us…but maybe they won’t! If you’re finding yourself going in circles trying to do your own critique, then put your messy draft in front of other readers. Feedback from other readers is always surprising. Two things will happen:"
      />
      <SuawParagraph
        text="<ol><li>They will love things that you thought they would hate, or things you didn’t even think about! One of my favorite experiences during the writing process is hearing from a reader that they loved a turn of phrase or a detail that I just hastily typed out and didn’t think about twice.</li><li>You will find readers will be confused by plot points or character developments that you yourself thought were really simple. Other readers will notice all kinds of things you can choose to revise at a later stage. But for now, the task at hand is your draft, which is begging to see the light of day. Give your draft the gift that it deserves by bringing it into the world, inner critic be damned!</li></ol>"
      />
      <SuawHeading level="3" content="5. Attend a weekly in-person writing session with other focused writers" />
      <SuawParagraph
        text="This is a great way to temporarily silence your inner critic because you’re surrounded by other writers who are busy scribbling or typing away, which makes you want to do the same."
      />
      <SuawParagraph
        text="If you haven’t already, attend one of the <a target='_blank' title='https://www.meetup.com/pro/shut-up-write' href='https://www.meetup.com/pro/shut-up-write'>weekly Shut Up &amp; Write! events scheduled in your area</a> or <a title='/online-events' href='/online-eventse'>one of our online writing events</a>. If one isn’t nearby, start one of your own by visiting <a title='Become a Shut Up &amp; Write! Host' href='/host'>shutupwrite.com/host</a>."
      />
      <SuawParagraph
        text="Remember – the most important thing is to get words on the page. Critique, revision, editing, publishing, and best-selling fame all come later, after you have something to show to peers or editors or agents. Learning how to silence your inner critic for just enough time to make progress on your project by actually writing is a skill well worth practicing!"
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
